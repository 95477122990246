<template>
  <div class="channel-manage">
    <app-block-flex>
      <app-query-bar
        :queryConfig="queryConfig"
        v-model="queryData"
        @onSearch="fetchChannels" />
    </app-block-flex>
    <app-block-flex>
      <app-spacing>
        <Button
          v-if="permissions[CHANNEL_ACTION.CREATE]"
          type="warning"
          @click="() => onChannelAction(CHANNEL_ACTION.CREATE, true)">
          {{ CHANNEL_ACTION_TEXT[CHANNEL_ACTION.CREATE] }}
        </Button>
        <Button
          v-if="permissions[CHANNEL_ACTION.REBIND_CHANNEL]"
          type="primary"
          :disabled="allowedRebind"
          @click="() => onChannelAction(CHANNEL_ACTION.REBIND_CHANNEL, true)">
          {{ CHANNEL_ACTION_TEXT[CHANNEL_ACTION.REBIND_CHANNEL] }}
        </Button>
      </app-spacing>
      <app-export-bar
        @onExport="onExport"
        @onExportAll="onExportAll" />
    </app-block-flex>
    <app-data-table
      selectable
      :columns="CHANNEL_COLUMNS"
      :data="channels"
      :allowed-permissions="Object.values(ALLOWED_PERMISSION)"
      v-model="queryData"
      @onSelect="onSelect"
      @onPageConfigChange="fetchChannels">
      <template
        slot-scope="{ row }"
        slot="level">
        {{LEVEL_TEXT[row.level]}}
      </template>
      <template
        slot-scope="{ row }"
        slot="status">
        {{ STATUS_TEXT[row.status] }}
      </template>
      <app-spacing
        spacing="sm"
        class="table-action"
        slot-scope="{ row }"
        slot="action">
        <Button
          v-if="permissions[CHANNEL_ACTION.EDIT]"
          type="primary"
          @click="() => onChannelAction(CHANNEL_ACTION.EDIT, true, row.id)">
          编辑
        </Button>
        <Button
          v-if="permissions[CHANNEL_ACTION.RESET]"
          type="primary"
          @click="() => onChannelAction(CHANNEL_ACTION.RESET, true, row.id)">
          重置密码
        </Button>
        <Button
          v-if="permissions[CHANNEL_ACTION.STATUS_TOGGLE]"
          :type="row.status === STATUS.ENABLE ? 'error' : 'primary'"
          @click="() => onChannelAction(CHANNEL_ACTION.STATUS_TOGGLE, true, row.id, row.status)">
          {{ REVERSE_STATUS_TEXT[row.status] }}
        </Button>
      </app-spacing>
    </app-data-table>
    <app-modal
      :value="channelModalConfig.visible"
      :title="channelActionText"
      @on-ok="onModalConfirm"
      @on-cancel="() => onChannelAction(null, false)">
      <Form
        ref="channelForm"
        :model="channelModalData"
        :rules="channelModalRule"
        :label-position="labelPosition"
        :label-width="labelWidth">
        <template v-if="channelModalConfig.action === CHANNEL_ACTION.REBIND_CHANNEL">
          <FormItem
            label="请选择渠道或运营作为新的直属上级"
            prop="bindType">
            <app-spacing class="rebind-form-content">
              <Select
                v-model="channelModalData.bindType">
                <Option
                  v-for="item in REBIND_TYPE_OPTIONS"
                  :value="item.value"
                  :key="item.value">
                  {{ item.label }}
                </Option>
              </Select>
              <Select
                filterable
                clearable
                v-if="channelModalData.bindType === REBIND_TYPE.CHANNEL"
                v-model="channelModalData.newBindParentId">
                <Option
                  v-for="item in channelModalConfig.channelOptions"
                  :value="item.value"
                  :key="item.value">
                  {{ item.label }}
                </Option>
              </Select>
            </app-spacing>
          </FormItem>
        </template>
        <template v-else-if="
            channelModalConfig.action === CHANNEL_ACTION.CREATE ||
            channelModalConfig.action === CHANNEL_ACTION.EDIT
          ">
          <FormItem
            v-if="channelModalConfig.action === CHANNEL_ACTION.EDIT"
            label="渠道ID">
            {{ channelModalData.id }}
          </FormItem>
          <FormItem
            label="渠道名称"
            prop="name">
            <Input
              v-model="channelModalData.name"
              :placeholder="TIPS.NAME"/>
          </FormItem>
          <FormItem
            label="渠道管理员"
            prop="nickName">
            <Input
              v-model="channelModalData.nickName"
              :placeholder="TIPS.NICKNAME"/>
          </FormItem>
          <FormItem
            label="管理员手机号"
            prop="mobile">
            <Input
              v-model="channelModalData.mobile"
              :placeholder="TIPS.MOBILE"/>
          </FormItem>
          <FormItem
            v-if="channelModalConfig.action === CHANNEL_ACTION.CREATE && userType !== 'CHANNEL'"
            label="直属上级"
            prop="parentId">
            <Select
              filterable
              clearable
              v-model="channelModalData.parentId"
              :placeholder="TIPS.PARENT_ID">
              <Option
                v-for="item in channelModalConfig.channelOptions"
                :value="item.value"
                :key="item.value">
                {{ item.label }}
              </Option>
            </Select>
            <span style="color: red">*此处不选择默认为一级渠道商</span>
          </FormItem>
          <FormItem
            label="地址"
            prop="address">
            <Input
              :maxlength="50"
              v-model="channelModalData.address"
              :placeholder="TIPS.ADDRESS"/>
          </FormItem>
          <FormItem
            label="联系人邮箱"
            prop="email">
            <Input
              v-model="channelModalData.email"
              :placeholder="TIPS.EMAIL"/>
          </FormItem>
          <FormItem
            label="分成比例"
            prop="divideRatio">
            <InputNumber
              class="divide-ratio"
              :min="0"
              v-model="channelModalData.divideRatio"
              :placeholder="TIPS.DIVIDE_RATIO"/>
          </FormItem>
          <FormItem
            label="开户银行"
            prop="bank">
            <Input
              v-model="channelModalData.bank"
              :placeholder="TIPS.BANK"/>
          </FormItem>
          <FormItem
            label="开户银行账号"
            prop="bankAccount">
            <Input
              v-model="channelModalData.bankAccount"
              :placeholder="TIPS.BANK_ACCOUNT"/>
          </FormItem>
          <FormItem
            label="企业开户名称"
            prop="bankBusinessName">
            <Input
              v-model="channelModalData.bankBusinessName"
              :placeholder="TIPS.BANK_BUSINESS_NAME"/>
          </FormItem>
        </template>
        <template v-else-if="channelModalConfig.action === CHANNEL_ACTION.RESET">
          <p>
            重置后，该渠道登录密码将恢复为系统默认登录密码，是否继续操作？
          </p>
        </template>
      </Form>
    </app-modal>
  </div>
</template>

<script>
import {
  ALLOWED_PERMISSION,
  CHANNEL_ACTION,
  CHANNEL_ACTION_TEXT,
  CHANNEL_COLUMNS,
  STATUS,
  STATUS_TEXT,
  REVERSE_STATUS_TEXT,
  STATUS_OPTIONS,
  LEVEL,
  LEVEL_TEXT,
  LEVEL_OPTIONS,
  TIPS,
  REBIND_TYPE,
  REBIND_TYPE_OPTIONS,
  PROVIDE_ID,
} from './constants';
import ChannelManageApi from './channel-manage.api';
import DataTransferUtil from '../../../utils/data-transfer.util';

export default {
  name: 'channel-manage',
  computed: {
    userType() {
      return this.$store.getters.userType;
    },
    channelActionText() {
      return CHANNEL_ACTION_TEXT[this.channelModalConfig.action];
    },
    allowedRebind() {
      const { selectChannelIds, bindParentId } = this.channelModalData;

      return !(bindParentId?.toString()
        && selectChannelIds.length !== 0);
    },
    labelPosition() {
      return this.channelModalConfig.action === CHANNEL_ACTION.REBIND_CHANNEL
        ? 'top' : 'left';
    },
    labelWidth() {
      return this.channelModalConfig.action === CHANNEL_ACTION.REBIND_CHANNEL
        ? null : 100;
    },
    queryConfig() {
      const result = [
        {
          fieldName: 'name',
          name: '名称',
        },
        {
          fieldName: 'nickName',
          name: '管理员',
        },
        {
          fieldName: 'parentChannelId', // 运营模式可见
          name: '直属上级',
          type: 'select',
          options: this.queryConfigChannelOptions,
        },
        {
          fieldName: 'status',
          name: '状态',
          type: 'select',
          options: STATUS_OPTIONS,
        },
        {
          fieldName: 'level', // 运营模式可见
          name: '类型',
          type: 'select',
          options: LEVEL_OPTIONS,
        },
      ];

      if (!this.permissions[CHANNEL_ACTION.REBIND_CHANNEL]) {
        result.splice(result.findIndex((item) => item.fieldName === 'parentChannelId'), 1);
      }

      return result;
    },
    CHANNEL_COLUMNS() {
      const allowedPermissionValues = Object.values(ALLOWED_PERMISSION);
      const count = Object
        .keys(this.permissions)
        .filter((k) => allowedPermissionValues.includes(k))
        .length;
      const rebuildColumns = [...CHANNEL_COLUMNS];

      return rebuildColumns.map((c) => {
        if (c.slot === 'action') {
          // eslint-disable-next-line no-param-reassign
          c.width = count * 85;
        }
        return c;
      });
    },
  },
  data() {
    return {
      ALLOWED_PERMISSION,
      CHANNEL_ACTION,
      CHANNEL_ACTION_TEXT,
      STATUS,
      STATUS_TEXT,
      REVERSE_STATUS_TEXT,
      LEVEL_TEXT,
      TIPS,
      REBIND_TYPE,
      REBIND_TYPE_OPTIONS,
      queryConfigChannelOptions: [],
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        name: '',
        nickName: '',
        parentChannelId: null,
        status: STATUS.ALL,
        level: LEVEL.ALL,
      },
      channels: [],
      channelModalConfig: {
        visible: false,
        action: null,
        channelOptions: [],
      },
      channelModalData: {
        id: null,
        name: '',
        nickName: '',
        mobile: '',
        parentId: null,
        address: '',
        email: '',
        divideRatio: null,
        bank: '',
        bankAccount: '',
        bankBusinessName: '',
        bindType: REBIND_TYPE.CHANNEL,
        bindParentId: null,
        newBindParentId: null,
        selectChannelIds: [],
        status: STATUS.ENABLE,
      },
      channelModalRule: {
        bindType: [
          { required: true, message: TIPS.REBIND_TYPE, trigger: 'change' },
          {
            checkedField: 'newBindParentId',
            trigger: 'change',
            validator: this.rebindModalValidator,
          },
        ],
        name: [{ required: true, message: TIPS.NAME, trigger: 'change' }],
        nickName: [{ required: true, message: TIPS.NICKNAME, trigger: 'change' }],
        mobile: [{ required: true, message: TIPS.MOBILE, trigger: 'change' }],
        address: [{ required: true, message: TIPS.ADDRESS, trigger: 'change' }],
        divideRatio: [{
          required: true,
          type: 'number',
          message: TIPS.DIVIDE_RATIO,
          trigger: 'change',
        }],
      },
    };
  },
  created() {
    this.fetchParentChannels();
    this.fetchChannels();
  },
  methods: {
    async fetchChannels() {
      const {
        name, page, limit, mobile, nickName, parentChannelId, status, level,
      } = this.queryData;

      this.channelModalData = {
        ...this.channelModalData,
        bindParentId: parentChannelId,
        newBindParentId: null,
        selectChannelIds: [],
      };

      const {
        count = 0,
        results = [],
      } = await ChannelManageApi.getChannels(
        page,
        limit,
        name,
        nickName,
        NaN,
        parentChannelId,
        mobile,
        status,
        level,
      );
      this.channels = results;
      this.$set(this.queryData, 'count', count);
    },
    async fetchChannelDetail(channelId = NaN) {
      const {
        id,
        name,
        email,
        address,
        bank,
        bank_account: bankAccount,
        bank_business_name: bankBusinessName,
        divide_ratio: divideRatio,
        nick_name: nickName,
        mobile,
      } = await ChannelManageApi.getChannelDetail(channelId);

      this.channelModalData = {
        ...this.channelModalData,
        id,
        name,
        nickName,
        mobile,
        address,
        email,
        divideRatio,
        bank,
        bankAccount,
        bankBusinessName,
      };
    },
    async fetchParentChannels() {
      const result = await ChannelManageApi.getChannelsByChannelName();
      const options = DataTransferUtil.originTransfer2Options(result);
      this.queryConfigChannelOptions = options;

      if (this.userType === 'ADMIN') {
        this.queryConfigChannelOptions.unshift({ label: '运营', value: 0 });
      }
      this.$set(this.channelModalConfig, 'channelOptions', options);
    },
    // TODO: 缺少提示条件
    // async toggleChannelStatus(id = NaN, status = STATUS.ENABLE) {
    //   await ChannelManageApi.toggleChannelState(
    //     id,
    //     status === STATUS.ENABLE ? STATUS.DISABLE : STATUS.ENABLE,
    //   );
    //
    //   await this.fetchChannels();
    // },
    onSelect(selections) {
      this.$set(this.channelModalData, 'selectChannelIds', selections.map((selection) => selection.id));
    },
    async onChannelAction(
      action = CHANNEL_ACTION.CREATE,
      visible = false,
      channelId = NaN,
      status = STATUS.ENABLE,
    ) {
      if (visible) {
        this.$refs.channelForm.resetFields();
      }

      if (action === CHANNEL_ACTION.EDIT) {
        await this.fetchChannelDetail(channelId);
      } else if (action === CHANNEL_ACTION.STATUS_TOGGLE) {
        this.channelModalData = {
          ...this.channelModalData,
          id: channelId,
          status,
        };
        this.channelModalConfig = {
          ...this.channelModalConfig,
          action,
        };
        await this.onModalConfirm();
        return;
      } else {
        this.channelModalData = {
          ...this.channelModalData,
          id: channelId,
          name: '',
          nickName: '',
          mobile: '',
          parentId: null,
          address: '',
          email: '',
          divideRatio: null,
          bank: '',
          bankAccount: '',
          bankBusinessName: '',
          bindType: REBIND_TYPE.CHANNEL,
          status,
        };
      }

      this.channelModalConfig = {
        ...this.channelModalConfig,
        visible,
        action,
      };
    },
    async onModalConfirm() {
      this.$refs.channelForm.validate(async (valid) => {
        if (!valid) {
          return;
        }

        const {
          id,
          name,
          nickName,
          mobile,
          parentId,
          address,
          email,
          divideRatio,
          bank,
          bankAccount,
          bankBusinessName,
          bindType,
          bindParentId,
          newBindParentId,
          selectChannelIds,
          status,
        } = this.channelModalData;

        switch (this.channelModalConfig.action) {
          case CHANNEL_ACTION.CREATE:
            await ChannelManageApi.createChannel(
              name,
              nickName,
              mobile,
              email,
              address,
              divideRatio,
              bank,
              bankAccount,
              bankBusinessName,
              parentId,
            );
            break;
          case CHANNEL_ACTION.EDIT:
            await ChannelManageApi.editChannel(
              id,
              name,
              nickName,
              mobile,
              email,
              address,
              divideRatio,
              bank,
              bankAccount,
              bankBusinessName,
            );
            break;
          case CHANNEL_ACTION.REBIND_CHANNEL:
            await ChannelManageApi.reDistributeParentChannel(
              selectChannelIds.toString(),
              bindParentId,
              bindType === REBIND_TYPE.CHANNEL ? newBindParentId : PROVIDE_ID,
            );
            break;
          case CHANNEL_ACTION.STATUS_TOGGLE:
            try {
              await ChannelManageApi.toggleChannelState(
                id,
                status === STATUS.ENABLE ? STATUS.DISABLE : STATUS.ENABLE,
              );
            } catch (e) {
              this.$Modal.info({
                title: '提示',
                content: e?.msg,
              });
            }
            break;
          case CHANNEL_ACTION.RESET:
            await ChannelManageApi.resetPwd(id);
            break;
          default:
            break;
        }

        await this.onChannelAction(null);
        await this.fetchChannels();
      });
    },
    rebindModalValidator(rule, value, callback) {
      const { checkedField } = rule;
      if (value === REBIND_TYPE.CHANNEL && !this.channelModalData[checkedField]?.toString()) {
        callback([TIPS.BIND_CHANNEL_ID]);
      }

      callback();
    },
    async onExport() {
      const {
        page,
        limit,
        name,
        nickName,
        parentChannelId,
        status,
        level,
      } = this.queryData;
      const key = await ChannelManageApi.export({
        page,
        limit,
        name,
        nick_name: nickName,
        parent_channel_id: parentChannelId,
        status,
        level,
      });
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async  onExportAll() {
      const key = await ChannelManageApi.export();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
  },
};
</script>

<style lang="less" scoped>
.rebind-form-content {
  display: flex;
  > div {
    &:first-child {
      flex: 1 3 auto;
    }
    &:last-child {
      flex: 1 1 auto;
    }
  }
}

.divide-ratio {
  width: 100%;
}
</style>
